<template>
  <div>
    <v-container>
    <navbar title="دسته بندی ها"/>
    <loading-page v-if="loading"/>
    <v-main class="pt-15">
    <v-row class="pa-1">
      <v-col v-for="categorie in categories" :key="categorie.id" cols="6" class="">
        <div @click="category_subordinate(categorie)" class="d-flex flex-column align-center justify-center">
          <v-avatar size="150" tile>
          <v-img :src="categorie.icon"></v-img>
          </v-avatar>
          <span class="font mt-1 font-weight-bold onSurface--text" v-text="categorie.title"></span>
        </div>
      </v-col>
    </v-row>
    </v-main>
    </v-container>
  </div>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import { categories_list } from './models/categories'
import { mapState } from 'vuex'
import store from '@/store'
import { search_product_tag } from '@/views/shop/search/models/search';
import LoadingPage from '../../../components/tools/loadingPage.vue';
import router from '@/router'
export default {
  props: ['category'],
  components: {
    navbar,
    LoadingPage
  },
  computed: {
    ...mapState({
      categories: state => state.categories.categories,
      loading: state => state.search.load_page,
    })
  },
  created () {
    localStorage.removeItem('filter_search')
    if (this.$props.category) {
      categories_list(localStorage.getItem('category_id'))
    } else {
      categories_list('')
    }
  },
  watch: {
    category: {
      deep: true,
      handler: function () {
        if (this.$props.category) {
      categories_list(localStorage.getItem('category_id'))
    } else {
      categories_list('')
    }
    }
    }
  },
  methods: {
    category_subordinate (category) {
      if (category.children.length == 0) {
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
          let tages = ''
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = key + ',' + tages
            }
          } else {
            tages = category.tag[0]
          }
          filters.tags = tages
          filters.title = {title: category.title, value: 'tags'}
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
            search_product_tag(filters)
          }, 500)
        } else {
          filters = {}
          filters.page = 1
          let tages = ''
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = key + ',' + tages
            }
          } else {
            tages = category.tag[0]
          }
          filters.tags = tages
          filters.title = {title: category.title, value: 'tags'}
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
          search_product_tag(filters)
          }, 500)
        }
      } else {
        categories_list(category.id)
        localStorage.setItem('category_id', category.id)
        router.push(`/categories/${category.title}`)
      }
    }
  }
}
</script>